import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { Col, Grid, Row } from "react-flexbox-grid"

import Button from "../components/Button"
import {
  StyledSection,
  SectionIntro,
  IntroSubtitle,
  buttonWrapper,
  IntroHeader,
  StyledSubheader,
  SectionContentBlock,
  IntroContent,
  ExperienceTitle,
  ExperienceSub,
  SocialLink,
  SubTitle,
} from "../pageStyles/pageStyles"



class SensibullCaseStudy extends React.Component {
  render() {

    return (
      <div>
        <SEO title="Sensibull Case Study"/>
        {/*<AdventureLandBrand />*/}
        <Grid fluid>
          <Row between="xs">
            <Col xs={12}>
              <IntroHeader>
                Sensibull
              </IntroHeader>
              <SubTitle>
                Options Trading Platform
              </SubTitle>
            </Col>
          </Row>
          <section>
            <Row>
              <Col xs={12} sm={8} smOffset={2}>
                <h1>
                  Overview
                </h1>
                <p>

                  Sensibull was founded with the absurdly impossible dream of making the investors profitable in stock markets. We started as India’s first Options Trading Platform and are gradually transforming into a complete wealth management platform. This is a very large market, and pretty much the cutting edge of fintech as we have already realized.

                  Sensibull is funded by and partnered with Zerodha, the largest broker in India. We are operationally profitable, partnering with more brokers, rapidly growing, and going all in.

                </p>
                <h1>
                  Problem

                </h1>
                <p>
                  Most retail investors blow up their money on Futures and Options Trading. More specifically, on Options. If there is one place to start saving people from getting wiped off, that is options trading. So that is where we start.

                  “According to SEBI 99.9% of the retail derivatives traders was losing money.”
                </p>
                <h1>
                  My Role
                </h1>
                <p>
                  When I joined sensibull I was the 4 member and the only designer. So I had to wear multiple hats to execute the MVP and quickly release the beta to closed user group. I helped team to solve the problems by design and delivered working react UI components. Working on design and code simultaneously helped team to reduce friction and increase the execution time, was very helpful for iteration my design solutions.
                </p>
                <h1>
                  Projects
                </h1>
                <p>Sensibull solved the options trading problems that faced by traders day to day. There was no product in the market providing end to end solution for options trading.  So we were creating a unique product that for a niche market segment. Initial time most of the bandwidth I have spent on brainstorming with team ideating different solution and presenting the to the team and close user groups. Sensibull have sub products that has a scope of itself. I’m separating the projects here for ease of scoping.</p>


                <h1>
                  AARRR Metric Funnel
                </h1>
                <p>
                  Major part of the user acquisition was from zerodha (partnered broker)  and its ecosystem like www.tradingqna.com , www.zconnect.com ,  kite push notifications and kite in app banner and new alerts.  Now same goes to newly integrated brokers at the time of writing we have already partnered with 5paisa, aliceblue and motilal oswal.

                  Google login provides access to sensibull platform with all the feature excluding placement of trading. This allows users to easily get familiar with product and later convert to any of existing brokers.

                  Other sources including marketing websites www.sensibull.com , @beSensibull twitter page and YouTube education videos also place major part of marketing. Most of the feature request and direct conversation with customers are happening here.

                  Youtube live webinars and educational materials are big source of user engagement on the brand. Even Weekends people engage with our webinars and share their insights about the webinar on Twitter later.

                </p>
                <h4>Metrics</h4>
                <p>
                  Twitter 11k followers, youtube 20k Subscribers,  above 63k views
                  100+ visits in marketing pages in 2 hours timespan
                  Not able to provide the user activation on product right now
                </p>




                <p>
                  We have to start from somewhere so we start with the retail investors according to SEBI retail investors lose 99.9 percentage of their invest so we thought this is a good place to start

                  Want to actually check retail investors need they want to earn good returns possibly peaceful night spend time with their family learn about the strategies sent stock market share stock market tips tricks with their fellow friends but ultimate goal is to make money earned money and have a good life

                  So we have created a better way to solve the problems by providing collection of tools and options for trading a lot of mathematical formulas Are applied

                  Most of the retailer times spends on learning and understanding the strategy and the man behind it

                  So the 1st to be together the least amount of data about the market from the user exam you should think the market will go up my hundred points then how to decide trade based on that so have to strategy generator will take the input from the user and generate the best possible option treat profitable option treat her shown by calculating all the formulas mathematical formulas from the backend and shown to the user within a fraction of second this feature Hell do I want silly mistakes like upcoming events popular to property of profit the rate of return in single scan a user can make a treat from one single click







                  No the previous feature is targeted to the use whole is little early in the trading and doesn’t know much that support strategy making so making a smart strategy need to have an understanding understanding deep understanding of the domain so we have built a protocol pro tour tool called builder for the pro option tradersThis allows to make custom strategies or use any Pritham Rai strategy from the template and starch create to treat after creating a strategy used several get to know the upcoming events and insights and warnings about the trades and common mistakes and soul so and also are you sick and headlights a pair of chat and get to know about the Greek Greek’s S



                  Here goes the screenshot of a builder



                  Now we have targeted both know voice and the pro tractor and after tracking Tre Tre it is a sensual to track their positions use a need to track their positions that is the crucial part of the trading the need to watch analyse the current situation the current market and order based on the situation so this so this persistence page need to be smart you should add advise people report upcoming events change in market insights about the About the About the treatAnd show you some warnings and insights and hello and also should I Hello users to get out of the trade and that is the treat


                  Here goes the screenshot of positions

                </p>









              </Col>


            </Row>
          </section>
        </Grid>

      </div>
    )
  }
}

export default SensibullCaseStudy