import styled from "styled-components"

import {fontFamilyContent} from "../utils/variables"

export const IntroHeader = styled.h1`
  font-size: 38px;
  line-height: 54px;
  letter-spacing: -0.011em;
  font-weight: 800;
  margin-bottom: 90px;
  max-width: 620px;
`;

export const IntroContent = styled.div`
  //max-width: 620px;
`;

export const StyledSubheader = styled.h2`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin-bottom: 0.666rem;
  
`
export const SectionContentBlock = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-family: ${fontFamilyContent};
`

export const SectionIntro = styled.div`
  font-size: 18px;
  font-family: ${fontFamilyContent};
  margin-bottom: 1.666rem;
  font-weight: 600;
`;

export const StyledSection = styled.div`
margin-top: 60px;
`;

export const IntroSubtitle = styled.div`
  color: #797979;
`;

export const buttonWrapper = {
  marginBottom: 24,
}

export const ExperienceTitle = styled.h4`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const ExperienceSub = styled.p`
  font-size: 18px;
  color: #797979;
  font-family: ${fontFamilyContent};
`;

export const SocialLink = styled.a`
  font-size: 18px;
  margin-bottom: 8px;
  text-decoration: none;
  border-bottom: 1px solid #ccc;
  display: flex;
  cursor:pointer;
  padding: 4px 0;
  
  &:visited,
  &:active,
  &:focus {
    color: #101010;
  }
  
  &:hover {
    opactiy: 0.5 !important;
  }
`

export const SubTitle = styled.div`
  
`